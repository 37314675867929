import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import blogTemplateStyles from "./blogTemplate.module.scss"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Grid from "@material-ui/core/Grid"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description
      publishedDate(formatString: "DD/MMMM/YYYY", locale: "es")
      body {
        json
      }
      leadMagnetCta
      leadMagnet {
        file {
          url
        }
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  const findSeoImage = props.data.contentfulBlogPost.body.json.content.find(
    function(node) {
      return node.nodeType === "embedded-asset-block"
    }
  )
  const seoImage = findSeoImage.data.target.fields.file["en-US"].url
  const leadMagnetCta = props.data.contentfulBlogPost.leadMagnetCta
    ? props.data.contentfulBlogPost.leadMagnetCta
    : ""
  const leadMagnetFile = props.data.contentfulBlogPost.leadMagnet
    ? props.data.contentfulBlogPost.leadMagnet.file.url
    : ""

  function FirefacebookPixel() {
    window.fbq("track", "LeadMagnetDonwload")
  }

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <SEO
        title={props.data.contentfulBlogPost.title}
        url={`/blog/${props.data.contentfulBlogPost.slug}/`}
        image={`https:${seoImage}`}
        description={props.data.contentfulBlogPost.description}
      />
      <div className={blogTemplateStyles.container}>
        <h1 className={blogTemplateStyles.title}>
          {props.data.contentfulBlogPost.title}
        </h1>
        <p className={blogTemplateStyles.date}>
          {props.data.contentfulBlogPost.publishedDate}
        </p>
        {documentToReactComponents(
          props.data.contentfulBlogPost.body.json,
          options
        )}
        {leadMagnetCta ? (
          <a
            class={blogTemplateStyles.title}
            href={leadMagnetFile}
            title={`Descarga ${leadMagnetCta}`}
            target="blank"
            onClick={FirefacebookPixel}
            rel="noopener noreferrer"
          >
            {leadMagnetCta}
          </a>
        ) : (
          ""
        )}
        <Grid container className={blogTemplateStyles.socialContainer}>
          <Grid
            item
            xs={12}
            sm={4}
            className={blogTemplateStyles.shareTextContainer}
          >
            <AniLink
              title="blog"
              className={blogTemplateStyles.svgLink}
              swipe
              direction="right"
              to="/blog"
            >
              <svg
                className={blogTemplateStyles.svg}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "#141a3a" }}
                  d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
                                    C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
                                    c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"
                />
              </svg>
              <p style={{ margin: "0 0 0 1em" }}>Regresar</p>
            </AniLink>
          </Grid>
        </Grid>
        <div
          class="fb-comments"
          data-href={`https://www.uniomx.com/blog/'${props.data.contentfulBlogPost.slug}/`}
          data-width="800px"
          data-numposts="5"
        ></div>
      </div>
    </div>
  )
}

export default Blog
